<template>
  <v-card>
    <v-card-title>
      Aircraft
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" outlined color="indigo" @click="add()">
        Add New
      </v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="browse"
      :search="search"
      :loading="loading"
      loading-text="LOADING.... PLEASE WAIT"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.index="{ item }">
        {{ item.index }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.rpas_color="{ item }">
        <v-avatar :color="`${item.rpas_color}`" size="24"></v-avatar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.actions="{ item }">
        <span>
          <v-btn depressed text color="primary" @click="read(item)">
            <v-icon left dark>
              {{ icons.mdiPencil }}
            </v-icon>
          </v-btn>
          <v-btn depressed text color="error" @click="trash(item.id)">
            <v-icon dark>
              {{ icons.mdiDelete }}
            </v-icon>
          </v-btn>
        </span>
      </template>
    </v-data-table>

    <v-dialog v-model="addAirCraft" max-width="1080px">
      <v-card class="pa-15">
        <v-tabs color="primary" left>
          <v-tab>Details</v-tab>
          <v-tab-item>
            <aircraft-add></aircraft-add>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editAirCraft" max-width="1080px">
      <v-card class="pa-15">
        <v-tabs color="primary" center>
          <v-tab>Details</v-tab>
          <v-tab>Maintenance</v-tab>
          <v-tab>Folio Entry</v-tab>

          <v-tab-item>
            <aircraft-read :aircraft="aircraft" @updateBrownse="updateBrownse"></aircraft-read>
          </v-tab-item>

          <v-tab-item>
            <aircraft-maintenance-read :aircraft="aircraft" @updateBrownse="updateBrownse"></aircraft-maintenance-read>
          </v-tab-item>

          <v-tab-item>
            <aircraft-folio-read :aircraft="aircraft" @updateBrownse="updateBrownse"></aircraft-folio-read>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mdiPencil, mdiDelete, mdiButterfly } from "@mdi/js";
import permission from "@/directive/permission"; // Permission directive (v-permission)
import AircraftAdd from "./AircraftAdd.vue";
import AircraftRead from "./AircraftRead.vue";
import AircraftMaintenanceRead from "./Maintenance.vue";
import AircraftFolioRead from "../AircraftFolio/AircraftFolioRead.vue";
import Request from "@/core/api/request";

export default {
  name: "AircraftBrowse",
  directives: { permission },
  components: { AircraftAdd, AircraftMaintenanceRead, AircraftFolioRead, AircraftRead },
  data() {
    return {
      aircraft_id: "",
      aircraft: {},
      e1: 1,
      loading: true,
      addAirCraft: false,
      editAirCraft: false,
      search: "",
      browse: [],
      headers: [
        { text: "NO.", value: "index", sortable: true },
        { text: "Registration", value: "registration", sortable: false },
        { text: "Serial #", value: "serial_number", sortable: false },
        { text: "RPAS Color", value: "rpas_color", sortable: false },
        { text: "RLA Expiry", value: "rla_expiry", sortable: false },
        { text: "Hours At Entry", value: "hours_at_entry", sortable: false },
        { text: "Action.", value: "actions", sortable: false },
      ],
      icons: {
        mdiPencil,
        mdiDelete,
        mdiButterfly,
      },
    };
  },

  watch: {
    addAirCraft() {
      this.initialize();
    },
    editAirCraft() {
      this.initialize();
    },
  },

  computed: {
    perms() {
      return {
        read: this.$IYA.ACL.PERMISSION_USER_READ,
        add: this.$IYA.ACL.PERMISSION_USER_ADD,
        remove: this.$IYA.ACL.PERMISSION_USER_REMOVE,
      };
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      const { data, status } = await Request.get(`${this.$IYA.URI.AIRCRAFT__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        this.loading = false;

        return;
      }
      if (data.data.length < 1) {
        this.loading = false;

        return;
      }
      console.log(data.data);
      data.data.forEach((item, i) => {
        item.index = i + 1;
      });
      this.browse = data.data;
      this.loading = false;
    },

    updateBrownse() {
      this.initialize();
    },

    read(param) {
      this.aircraft = param;
      console.log(param);
      this.editAirCraft = true;
    },

    add() {
      this.addAirCraft = true;
    },

    trash(id) {
      this.$swal({
        title: `Are you sure?`,
        text: `You can't revert your action`,
        type: `warning`,
        showCancelButton: true,
        confirmButtonText: `Yes Delete it!`,
        cancelButtonText: `No, Keep it!`,
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(result => {
        if (result.value) {
          this.destroy(id);
        } else {
          this.$swal(`Cancelled`, `Your records are still intact`, `info`);
        }
      });
    },

    async destroy(param) {
      await Request.destroy(`${this.$IYA.URI.AIRCRAFT__DELETE}`, { id: param }).then(response => {
        if (response.data.success) {
          this.$swal(`Deleted`, `You successfully deleted this record`, `success`);
          this.initialize();
        } else {
          this.$swal(`Failed !`, `Failed to remove record`, `error`);
        }
      });
    },
  },
};
</script>
