<template>
  <v-container>
    <validation-observer ref="observer">
      <form class="p-2" @submit.prevent="submit">
        <v-row>
          <v-col cols="12">
            <validation-provider v-slot="{ errors }" name="Description" rules="required">
              <v-text-field
                v-model="aircraft_maintenances.description"
                :error-messages="errors"
                label="Description"
                required
                dense
                outlined
              >
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Due At Hours" rules="required">
              <custom-time-picker
                v-model="aircraft_maintenances.due_at_hours"
                required
                label="Due At Hours"
                :error-messages="errors"
                @forceTimeUpdate="forceTimeUpdate"
              ></custom-time-picker>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider v-slot="{ errors }" name="Due Date" rules="required">
              <date-picker-dialog
                v-model="aircraft_maintenances.due_date"
                :minimum_date="`1950-01-01`"
                :error-messages="errors"
                :label="`Due Date`"
                @forceDateUpdate="forceDateUpdate"
              ></date-picker-dialog>
            </validation-provider>
          </v-col>
        </v-row>
        <v-btn type="submit" color="primary" class="ma-10 pa-5">
          Save
        </v-btn>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import DatePickerDialog from "@/components/DatePickerDialog.vue";
import CustomTimePicker from "@/components/TimePickerDialog.vue";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "AircraftMaintenance",
  props: {
    aircraft: {
      type: [Object],
      default() {
        return {};
      },
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    DatePickerDialog,
    CustomTimePicker,
  },
  data() {
    return {
      aircraft_maintenances: {},
    };
  },
  watch: {
    aircraft() {
      if (this.aircraft.aircraft_maintenances.length > 0) {
        // eslint-disable-next-line
        this.aircraft_maintenances = this.aircraft.aircraft_maintenances[0];
      } else {
        this.aircraft_maintenances = {};
      }
    },
  },
  methods: {
    forceDateUpdate(value) {
      this.aircraft_maintenances.due_date = value;
    },
    forceTimeUpdate(value) {
      if (value) {
        this.aircraft_maintenances.due_at_hours = value;
      }
    },
    updateBrownse() {
      this.$emit("updateBrownse");
    },
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        this.aircraft_maintenances.aircraft_id = this.aircraft.id;
        await Request.post(`${this.$IYA.URI.AIRCRAFT_MAINTENANCES__ADD}`, this.aircraft_maintenances).then(response => {
          if (response.status === this.$IYA.HTTP.OK) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
            this.updateBrownse();
          }
        });
        util.isLoading();
      }
    },
  },
};
</script>
