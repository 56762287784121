<template>
  <v-container>
    <validation-observer ref="observer">
      <form class="p-2" @submit.prevent="submit">
        <v-row>
          <v-col cols="12">
            <validation-provider v-slot="{ errors }" name="Defect or Maintenance" rules="required">
              <v-textarea
                v-model="aircraft_folio.defect"
                :error-messages="errors"
                label="Defect or Maintenance"
                required
                dense
                outlined
              ></v-textarea>
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider v-slot="{ errors }" name="Rectification Action" rules="required">
              <v-textarea
                v-model="aircraft_folio.rectification"
                :error-messages="errors"
                label="Rectification Action"
                dense
                required
                outlined
              >
              </v-textarea>
            </validation-provider>
          </v-col>
        </v-row>
        <v-btn type="submit" color="primary" class="ma-10 pa-5">
          Save
        </v-btn>
      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "AircraftFolioRead",
  props: {
    aircraft: {
      type: [Object],
      default() {
        return {};
      },
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      aircraft_folio: {},
    };
  },
  watch: {
    aircraft() {
      if (this.aircraft.aircraft_folio.length > 0) {
        // eslint-disable-next-line
        this.aircraft_folio = this.aircraft.aircraft_folio[0];
      } else {
        this.aircraft_folio = {};
      }
    },
  },
  methods: {
    updateBrownse() {
      this.$emit("updateBrownse");
    },
    async submit() {
      if (this.$refs.observer.validate()) {
        util.isLoading({ active: true, title: "Wait while we process." });
        this.aircraft_folio.aircraft_id = this.aircraft.id;
        await Request.post(`${this.$IYA.URI.AIRCRAFT_FOLIO__ADD}`, this.aircraft_folio).then(response => {
          if (response.status === this.$IYA.HTTP.OK) {
            this.$swal(`Done !`, `Action accomplished`, `success`);
            this.updateBrownse();
          }
        });
        util.isLoading();
      }
    },
  },
};
</script>
