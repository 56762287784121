var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('validation-observer',{ref:"observer"},[_c('form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Registration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Registration","required":"","outlined":"","dense":"","prepend-inner-icon":_vm.icons.mdiAccountOutline},model:{value:(_vm.aircraft.registration),callback:function ($$v) {_vm.$set(_vm.aircraft, "registration", $$v)},expression:"aircraft.registration"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Serial Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Serial Number","outlined":"","required":"","dense":"","prepend-inner-icon":_vm.icons.mdiCounter},model:{value:(_vm.aircraft.serial_number),callback:function ($$v) {_vm.$set(_vm.aircraft, "serial_number", $$v)},expression:"aircraft.serial_number"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Rpas Color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-color-picker',{attrs:{"required":"","label":"Hours At Entry","error-messages":errors},on:{"forceColorUpdate":_vm.forceColorUpdate},model:{value:(_vm.aircraft.rpas_color),callback:function ($$v) {_vm.$set(_vm.aircraft, "rpas_color", $$v)},expression:"aircraft.rpas_color"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"RLA Expiry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker-dialog',{attrs:{"minimum_date":"1950-01-01","error-messages":errors,"label":"RLA Expiry"},on:{"forceDateUpdate":_vm.forceDateUpdate},model:{value:(_vm.aircraft.rla_expiry),callback:function ($$v) {_vm.$set(_vm.aircraft, "rla_expiry", $$v)},expression:"aircraft.rla_expiry"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.aircraft_types,"error-messages":errors,"item-text":"name","item-value":"id","menu-props":"auto","label":"Type","outlined":"","required":"","dense":"","prepend-inner-icon":_vm.icons.mdiEvPlugType1},model:{value:(_vm.aircraft.type_id),callback:function ($$v) {_vm.$set(_vm.aircraft, "type_id", $$v)},expression:"aircraft.type_id"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Hours At Entry","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-time-picker',{attrs:{"required":"","label":"Hours At Entry","error-messages":errors},on:{"forceTimeUpdate":_vm.forceTimeUpdate},model:{value:(_vm.aircraft.hours_at_entry),callback:function ($$v) {_vm.$set(_vm.aircraft, "hours_at_entry", $$v)},expression:"aircraft.hours_at_entry"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.aircraft_status,"error-messages":errors,"item-text":"name","item-value":"name","menu-props":"auto","label":"Status","outlined":"","required":"","dense":"","prepend-inner-icon":_vm.icons.mdiHeartPulse},model:{value:(_vm.aircraft.status),callback:function ($$v) {_vm.$set(_vm.aircraft, "status", $$v)},expression:"aircraft.status"}})]}}])})],1)],1),_c('v-btn',{staticClass:"ma-10 pa-5",attrs:{"type":"submit","color":"primary"}},[_vm._v(" Update ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }